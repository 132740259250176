const Footer = () => {
	return (
		<div className="footer-container">
			<div className="container-fluid ">
				<div className="row p-2">
					<div className="m-auto">
						<span className="footer-logo">CamaKlin</span>
					</div>
				</div>
				<div className="row p-2">
					<div className="col-md-4 col-sm-12">
						<h5>Ubicación</h5>
						<p>La Florida, región Metropolitana de Santiago</p>
					</div>

					<div className="col-md-4 col-sm-12">
						<h5>Horarios</h5>
						<p>De lunes a domingo 09:00 a 21:00</p>
					</div>
					<div className="col-md-4 col-sm-12">
						<h5>Contactos</h5>
						<p>+56972110953 / +56972102208</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Footer;
