import Footer from "./components/Footer";
import Header from "./components/Header";
import LogoWsp from "./components/LogoWsp";
import { Navigation } from "./routes/Navigation";

function App() {
	return (
		<div className="main-container" data-bs-theme="dark">
			<Header />
			<Navigation />
			<LogoWsp />
			<Footer />
		</div>
	);
}

export default App;
