interface Props {
    product: {
        title: string;
        description: string;
        value: string;
        src: string;
        characteristics: string[];
        contract?: string[]
    }
}

export const Details = ({ product }: Props) => {
    return (
        <div className="container vh-100 mt-4 mb-4">
            <div className="row">
                <h1>{ product.title }</h1>
            </div>
            <div className="row column-gap">
                <div className="col-sm-12 col-md-4 text-center">
                    <img
                        className="img-fluid"
                        src={product.src}
                        alt={product.title}
                        style={{
                            height: "250px",
                            width: "250px"
                        }}
                    />
                </div>
                <div className="col-sm-12 col-md-8">
                    <div>
                        <h5>Descripción</h5>
                        <p>{product.description}</p>
                    </div>
                    <div>
                        <h5>Caracteristicas</h5>
                        <ul>
                            {product.characteristics.map((characteristic, idx) => (
                                <li key={idx}>{characteristic}</li>
                            ))}
                        </ul>
                    </div>
                    {product.contract && product.contract.length && (
                        <>
                            <h5>Requisitos para el arriendo</h5>
                            <ul>
                                {product.contract.map((contract, idx) => (
                                    <li key={idx}>{contract}</li>
                                ))}
                            </ul>
                        </>
                        
                    )}
                    <div>
                        <h5>Valor</h5>
                        <p>{product.value}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}