export const productos = [
	{
		id: 1,
		name: "Cama clínica eléctrica",
		label: "Cama clínica eléctrica",
		img: "../static/img/c012-removebg.webp",
		template: "camaClinica",
		value: "$70.000 / mes",
		url: "cama-clinica-electrica",
	},
	{
		id: 2,
		name: "Colchón antiescaras",
		label: "Colchón antiescaras",
		img: "../static/img/colchon01-01.webp",
		template: "colchonAntiescaras",
		value: "$28.990",
		url: "colchon-antiescaras",
	},
	{
		id: 3,
		name: "Colchón clínico",
		label: "Colchón clínico",
		img: "../static/img/colchon02-01.webp",
		template: "colchonClinico",
		value: "$69.990",
		url: "colchon-clinico",
	},
	{
		id: 4,
		name: "Andador roller",
		label: "Andador roller",
		img: "../static/img/andador-01-removebg.webp",
		template: "andador",
		value: "$79.990",
		url: "andador-roller",
	},
];

export const services = [
	{
		id: 1,
		title: "Arriendo de camas clinicas electricas",
		description:
			"Servicio enfocado a mejorar la calidad de vida y cuidado de las personas en su hogar. Consultas a nuestro whatsapp",
		value: "$70.000/mes",
	},
	{
		id: 2,
		title: "Despacho e instalación",
		description:
			"Para tu comodidad contamos con servicios de translado, instalación y posterior retiro",
		value: "Desde $40.000",
	},
	{
		id: 3,
		title: "Articulos clínicos domiciliarios",
		description:
			"Contamos con colchones antiescaras, colchones clínicos 4 pliegues y andadores roller",
		value: "",
	},
];

export const detalleProductos = [
	{
		id: 1,
		name: "cama-clinica-electrica",
		title: "Cama clínica eléctrica",
		description:
			"Contamos con camas clínicas eléctricas de alta calidad, consta de 4 posiciones: Regula altura del respaldo, regula altura de las piernas, regula la altura de la cama respecto al suelo y regula de forma simultaneas respaldo y piernas. Se regula mediante control remoto de fácil manipulación",
		characteristics: [
			"Múltiples posiciones",
			"Control remoto",
			"Inclinación en respaldo y piernas",
			"Frenos en las 4 ruedas",
			"Motor silencioso",
		],
		value: "$70.000 / mes",
		contract: ["Carnet de identidad vigente", "Firma de contrato de arriendo", "Sin garantias"],
		src: "../static/img/c012-removebg.webp",
	},
	{
		id: 2,
		name: "colchon-antiescaras",
		title: "Colchón antiescaras",
		description:
			"Consta de 130 celdas con burbujas de movimiento alternante otorgando así mayor circulación y menos roce con la piel",
		characteristics: [
			"Previene aparición de lesiones",
			"Panel de control silencioso, simple y fácil de usar",
			"Tiempo de inflado de 7 a 10 minutos",
			"Soporta hasta 135 kilos",
			"Bajo consumo de energía",
			"Medidas: largo 190cm - ancho 82cm - alto 7cm",
		],
		value: "$28.990",
		src: "../static/img/colchon01-01.webp",
	},
	{
		id: 3,
		name: "colchon-clinico",
		title: "Colchón Clínico",
		description:
			"Colchón clínico 4 pliegues para camas clínicas de cualquier tipo, tanto eléctricas como manuales",
		characteristics: [
			"Posee 4 pliegues que se ajustan a todos los movimientos de las camas clínicas",
			"Materiales: tela oxford, lavable e impermeable",
			"Posee orificios de ventilación y cierre, facilitando así su lavado y desinfección",
			"Espuma de alta densidad, 8cm de espesor",
			"Dimensiones: 192cm largo - 85cm ancho - 8cm alto",
		],
		value: "$69.990",
		src: "../static/img/colchon02-01.webp",
	},
	{
		id: 4,
		name: "andador-roller",
		title: "Andador Roller",
		description:
			"Producto diseñado para personas con dificultad motora. Cuenta con frenos de piola ideales para pacientes que sufren de artrosis y las empuñaduras tienen formas anatómicas para mayor seguridad",
		characteristics: [
			"Seguro, cómodo y práctico",
			"Incluye una canasta para transportar objetos",
		],
		value: "$79.990",
		src: "../static/img/andador-01-removebg.webp",
	},
];
