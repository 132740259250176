import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import { DetailsPage } from "../components/test/DetailsPage";

export const Navigation = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/producto/:name" element={<DetailsPage />}/>
        </Routes>
      </Router>
    );
  }