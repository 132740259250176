import { useEffect, useRef, useState } from "react";

const Information = () => {
	const [isVisible, setIsVisible] = useState(false);
	const rowRef = useRef(null);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5, // El porcentaje de visibilidad necesario para que se active la clase
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
				} else {
					setIsVisible(false);
				}
			});
		}, options);

		if (rowRef.current) {
			observer.observe(rowRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<div className="container" style={{ marginBottom: "10px", color: "#2323cb" }} ref={rowRef}>
			<div className="row">
				<div className={`col-md-12 col-lg-6 box box-left ${isVisible ? "active" : ""}`}>
					<div>
						<img className="img-fluid" src="../static/img/furgon.webp" alt="Despacho" />
					</div>
				</div>
				<div className={`col-md-12 col-lg-6 box box-right ${isVisible ? "active" : ""}`}>
					<h3 style={{ fontWeight: "bold" }}>Despachos e instalación camas electricas</h3>
					<h4>Region Metropolitana desde los $40.000</h4>
				</div>
			</div>
		</div>
	);
};

export default Information;
