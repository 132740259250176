const Header = () => {
	return (
		<div>
			<nav className="header-container navbar navbar-expand-lg">
				<div className="container">
						<a className="navbar-brand logo" href="/">
							<h1 style={{
								fontSize: '20px'
							}}>
							CamaKlin - Arriendo de camas clínicas
							</h1>
						</a>
				</div>
			</nav>
		</div>
	);
};
export default Header;
