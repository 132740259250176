import Product from "./Product";

import { productos } from "../data";

const Products = () => {
	const products = productos;

	return (
		<div className="container mt-4 text-center">
			<div className="mb-4" style={{ textAlign: "center", color: "#2323cb" }}>
				<h2>Nuestros Productos</h2>
			</div>
			<div className="row products-container p-4" style={{ backgroundColor: "#ffffff" }}>
				{products.map((producto) => (
					<Product
						id={producto.id.toString()}
						key={producto.id}
						className={`col col-md-4 col-sm-12 products`}
						alt={producto.name}
						src={producto.img}
						label={producto.label}
						value={producto.value}
						url={producto.url}
					/>
				))}
			</div>
		</div>
	);
};
export default Products;
