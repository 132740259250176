const LogoWsp = () => {
	return (
		<div className="wsp-logo">
			<span>
				<a
					href="https://api.whatsapp.com/send?phone=56972110953"
					target="_blank"
					rel="noreferrer"
				>
					<i className="fa-brands fa-whatsapp fa-3xl" style={{ color: "#ffffff" }} />
				</a>
			</span>
		</div>
	);
};
export default LogoWsp;
