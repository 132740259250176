import Carousel from "../components/Carousel";
import Information from "../components/Information";
import Products from "../components/Products";
import Services from "../components/Services";

const Home = () => {
	return (
		<div style={{ position: "relative", backgroundColor: "rgb(251 251 251)" }}>
			<Carousel />
			<Services />

			<Products />
			<Information />
		</div>
	);
};
export default Home;
