import { useParams } from 'react-router-dom'
import { detalleProductos } from '../../data'
import { Details } from './Details'


export const DetailsPage = () => {
    const { name } = useParams();
    const producto = detalleProductos.find(detalle => detalle.name === name);

    return (
        <>
            {producto && <Details product={producto}/>}
        </>
    )
}