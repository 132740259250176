import { useNavigate } from 'react-router-dom'

interface Args {
	id: string;
	src: string;
	alt: string;
	label: string;
	className?: string;
	value?: string;
	url: string
}

const Product = ({ alt, label, url, src, className }: Args) => {
	const navigate = useNavigate()

	return (
		<>
			<div className={`col col-md-4 col-sm-12 product ${className}`}>
				<div className="product-card">
					<div
						className="d-flex text-center justify-content-center"
						style={{ width: "100%" }}
					>
						<img
							className="product-img"
							src={src}
							alt={alt}
							style={{
								width: "100%",
								height: "250px",
							}}
							onClick={() => navigate(`producto/${url}`)}
						/>
					</div>

					<p className="product-description"> {label} </p>
				</div>
			</div>
		</>
	);
};
export default Product;
