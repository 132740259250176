import { useEffect, useRef, useState } from "react";
import { services } from "../data";

const Services = () => {
	const [isRowVisible, setIsRowVisible] = useState(false);
	const rowRef = useRef(null);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5, // El porcentaje de visibilidad necesario para que se active la clase
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setIsRowVisible(true);
				} else {
					setIsRowVisible(false);
				}
			});
		}, options);

		if (rowRef.current) {
			observer.observe(rowRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	const servicios = services;

	return (
		<div className="container mt-4" style={{ }}>
			<div style={{ textAlign: "center", color: "#2323cb" }} ref={rowRef}>
				<h2>Nuestros Servicios</h2>
			</div>
			<div className="row p-4" style={{ boxSizing: "border-box", backgroundColor: "#ffffff" }}>
				{servicios.map((servicio) => (
					<div
						key={servicio.id}
						className={`col-sm-12 col-md-4 services ${isRowVisible ? "active" : ""}`}
					>
						<div>
							<h3>{servicio.title}</h3>
							<div>
								<p>{servicio.description}</p>
							</div>

							<p style={{ fontWeight: "bolder" }}>{servicio.value}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
export default Services;
