const Carousel = () => {
	return (
		<div style={{ height: "300px" }}>
			<div id="carouselExample" className="carousel slide mt-4">
				<div className="carousel-inner">
					<div className="carousel-item active text-center">
						<img
							src="../static/img/banner.webp"
							className="rounded img-fluid"
							alt="Arriendo cama clinica electrica"
						/>
					</div>
					<div className="carousel-item text-center">
						<img
							src="../static/img/cc-03-removebg.webp"
							className="rounded img-fluid"
							alt="cama clinica electrica"
						/>
					</div>
					<div className="carousel-item text-center">
						<img
							src="../static/img/c012-removebg.webp"
							className="rounded img-fluid"
							alt="cama clinica"
						/>
					</div>
				</div>
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExample"
					data-bs-slide="prev"
				>
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target="#carouselExample"
					data-bs-slide="next"
				>
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</div>
	);
};
export default Carousel;
